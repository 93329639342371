import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Icon from "theme/components/atoms/Icon";

import Text from "theme/components/atoms/Form/Input/Text";
import { useAutocompleteInput } from "theme/components/organisms/Autocomplete/useAutocomplete";

const labels = defineMessages({
  search: {
    id: "modules.Search.SearchBar.SearchInput.placeholder",
    defaultMessage: "Search",
  },
});

const SearchInput = ({
  name,
  value,
  id,
  onFocus,
  isExpanded,
  selected,
  onChange,
  isOpen,
  ...rest
}) => {
  const intl = useIntl();
  const autocompleteProps = useAutocompleteInput({ isExpanded, selected });

  return (
    <>
      <Text
        {...rest}
        {...autocompleteProps}
        itemProp="query-input"
        name={name}
        value={value}
        id={id}
        placeholder={intl.formatMessage(labels.search)}
        onFocus={onFocus}
        aria-label={intl.formatMessage(labels.search)}
        appearance="searchbar"
      />
      <Icon
        icon="search"
        color="black"
        size="medium"
        title="search"
        className="search-icon"
      />
    </>
  );
};

export default SearchInput;
